import { Queuable } from "@core/queue";
import { CreateEventOptions } from "@core/event";
import { Interval } from "@helper/Interval";
import { DataLayer, GTMDataLayer, GTMDataLayerEvent } from "@helper/DataLayer";
import DataLayerEvent from "../events/DataLayerEvent";
import { DataLayerTrackerEvents } from "@constants/events";

type Options = {
  checkRate: number;
  interval: Interval;
  eventTypePatternMap: Record<string, string[]>;
  virtualPageViewPatterns: string[];
  blacklistPatterns: string[];
  createEventOptions: CreateEventOptions;
  dataLayer: DataLayer;

  storage: Storage;
};

const DEFAULT__CHECKRATE = 250; // DataLayer not to be confused with ._data. DataLayer is primarily for GTM events
const DEFAULT__EVENT_TYPE = "generic";

export default class GTMDataLayerTracker {
  private readonly _queue: Queuable;
  private readonly _dataLayer: DataLayer;
  private readonly _interval: Interval;
  private readonly _checkRate: number;
  private readonly _eventTypePatternMap: Record<string, string[]>;
  private readonly _virtualPageViewPatterns: string[];
  private readonly _blacklistPatterns: string[];
  private readonly _createEventOptions: CreateEventOptions;
  private readonly _boundHandler = this.handler.bind(this);

  constructor(queue: Queuable, options?: Partial<Options>) {
    this._queue = queue;
    this._checkRate = options?.checkRate ?? DEFAULT__CHECKRATE;
    this._eventTypePatternMap = options?.eventTypePatternMap ?? {};
    this._virtualPageViewPatterns = options?.virtualPageViewPatterns ?? [];
    this._blacklistPatterns = options?.blacklistPatterns ?? [];
    this._createEventOptions = options?.createEventOptions ?? {};
    this._dataLayer = options?.dataLayer ?? new DataLayer([]);
    this._interval = options?.interval ?? new Interval();
  }

  static initialize(
    queue: Queuable,
    options?: Partial<Options>,
  ): GTMDataLayerTracker {
    const tracker = new GTMDataLayerTracker(queue, options);
    window.addEventListener(
      DataLayerTrackerEvents.FLUSH,
      tracker._boundHandler,
    );
    tracker._interval.start(tracker._boundHandler, tracker._checkRate);
    return tracker;
  }

  static cleanup(tracker: GTMDataLayerTracker): void {
    tracker._interval.stop();
    window.removeEventListener(
      DataLayerTrackerEvents.FLUSH,
      tracker._boundHandler,
    );
  }

  private handler(): void {
    const events = getNewEvents(this._dataLayer, this._blacklistPatterns);
    if (!events.length) return;
    const event = new CustomEvent(DataLayerTrackerEvents.NEW_EVENTS, {
      detail: {
        events,
      },
    });

    window.dispatchEvent(event);

    // convert events to neuron event format
    const neuronEvents = events.map((event) =>
      mapGTMEventToNeuronEvent(
        event,
        this._eventTypePatternMap,
        this._createEventOptions,
      ),
    );

    this._queue.push(...neuronEvents);
  }
}

function getNewEvents(
  dataLayer: DataLayer,
  blacklistPatterns: string[],
): GTMDataLayer {
  return dataLayer
    .read()
    .filter((event) => DataLayer.getEventName(event) !== "")
    .filter((event) =>
      blacklistPatterns.every(
        (pattern) =>
          new RegExp(pattern, "i").test(DataLayer.getEventName(event)) ===
          false,
      ),
    );
}

function getEventTypeFromEventName(
  eventTypePatternMap: Record<string, string[]>,
  eventName: string,
): string | null {
  for (const key in eventTypePatternMap) {
    const regex = new RegExp(eventTypePatternMap[key].join("|"));
    if (regex.test(eventName)) {
      return key;
    }
  }

  return null;
}

function mapGTMEventToNeuronEvent(
  event: GTMDataLayerEvent,
  eventTypePatternMap: Record<string, string[]>,
  createEventOptions: CreateEventOptions,
) {
  const name = DataLayer.getEventName(event);
  const type =
    getEventTypeFromEventName(eventTypePatternMap, name) ?? DEFAULT__EVENT_TYPE;
  return DataLayerEvent.create(type, event, createEventOptions);
}
