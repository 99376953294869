export const getDataFromWindow = (key: string): string | null => {
  if (window._data == null || window._data?.[key] == undefined) {
    return null;
  }

  return window._data?.[key];
};

export const getDataFromLocalStorage = (key: string): string | null => {
  if (!localStorage.getItem(key)) {
    return null;
  }
  return localStorage.getItem(key);
};
