import { DataLayerTrackerEvents } from "@constants/events";
import { DataLayer } from "@helper/DataLayer";
import type { MagazineArticleMetadata } from "@helper/event";

type Config = {
  dataLayer: DataLayer;
  eventPatterns?: string[];
};

/**
 * EXPERIMENTAL
 * This method can replace the default article metadata generator. It extracts
 * the article metadata from the first available page view event submitted to
 * Google Analytics (aka: window.dataLayer).
 */
export const onGenerateArticleMetadata = (
  config: Config,
): MagazineArticleMetadata => {
  const metadata: MagazineArticleMetadata = {
    abVariant: null,
    adBlocker: null,
    articleId: null,
    articleCount: null,
    author: null,
    article_type: null,
    cueArticleId: null,
    keyword: null,
    page: null,
    chapter1: null,
    chapter2: null,
    chapter3: null,
    chapter1En: null,
    chapter2En: null,
    chapter3En: null,
    content_url: null,
    level2: null,
    level2Ga: null,
    level2Local: null,
    contentCat: null,
    contentType: null,
    pubDate: null,
    content_publication_utc: null,
    content_last_updated_utc: null,
    title: null,
    titleGa: null,
    visitorCat: null,
  };

  if (!config?.dataLayer) {
    return metadata;
  }

  const articleMetadata = onGetArticleMetadataFromDataLayer(config);

  if (!articleMetadata) {
    return metadata;
  }

  metadata.articleId =
    (articleMetadata.articleId as string) ??
    (articleMetadata.articleid as string) ??
    null;
  metadata.article_type = (articleMetadata.article_type as string) ?? null;
  metadata.author =
    (articleMetadata.content_author as string) ??
    (articleMetadata.author as string) ??
    null;
  metadata.keyword =
    (articleMetadata.article_keyword as string) ??
    (articleMetadata.keyword as string) ??
    null;
  metadata.page = (articleMetadata.pagination as string) ?? null;
  metadata.chapter1 =
    (articleMetadata.content_level_1 as string) ??
    (articleMetadata.chapter1 as string) ??
    null;
  metadata.chapter2 =
    (articleMetadata.content_level_2 as string) ??
    (articleMetadata.chapter2 as string) ??
    null;
  metadata.chapter3 =
    (articleMetadata.content_level_3 as string) ??
    (articleMetadata.chapter3 as string) ??
    null;
  metadata.contentCat =
    (articleMetadata.content_category as string) ??
    (articleMetadata.contentcat as string) ??
    null;
  metadata.contentType =
    (articleMetadata.content_type as string) ??
    (articleMetadata.contenttype as string) ??
    null;
  metadata.content_url =
    (articleMetadata.content_url as string) ??
    (articleMetadata.url as string) ??
    null;
  metadata.cueArticleId = (articleMetadata.cue_articleid as string) ?? null;
  metadata.level2 =
    (articleMetadata.content_level_2 as string) ??
    (articleMetadata.level2 as string) ??
    null;
  metadata.pubDate =
    (articleMetadata.content_publication_date as string) ??
    (articleMetadata.pubdate as string) ??
    null;
  metadata.content_publication_utc =
    (articleMetadata.content_publication_utc as string) ??
    (articleMetadata.content_publication_date_utc as string) ??
    null;
  metadata.content_last_updated_utc =
    (articleMetadata.content_last_updated_utc as string) ??
    (articleMetadata.content_update_date_utc as string) ??
    null;
  metadata.title =
    (articleMetadata.page_name as string) ??
    (articleMetadata.titleGa as string) ??
    null;
  metadata.titleGa =
    (articleMetadata.page_name as string) ??
    (articleMetadata.titleGa as string) ??
    null;
  metadata.visitorCat =
    (articleMetadata.visitor_category as string) ??
    (articleMetadata.visitorcat as string) ??
    null;

  return metadata;
};

export function onGetArticleMetadataFromDataLayer(
  config: Config,
): Record<string, unknown> {
  const eventPatterns = config.eventPatterns ?? [];
  return (
    config.dataLayer.events.findLast(
      (data) =>
        data.event &&
        typeof data.event === "string" &&
        eventPatterns.includes(data.event),
    ) ?? {}
  );
}

const MAX_ATTEMPTS = 3;
const ATTEMPT_INTERVAL = 250;

function checkForDataLayer(attempts = 0): void {
  if (window.dataLayer) {
    window.dispatchEvent(new CustomEvent(DataLayerTrackerEvents.READY));
    return;
  }

  if (attempts >= MAX_ATTEMPTS) {
    window.dispatchEvent(new CustomEvent(DataLayerTrackerEvents.NOT_FOUND));
    return;
  }

  setTimeout(() => checkForDataLayer(attempts + 1), ATTEMPT_INTERVAL);
}

export function getDataLayerFromGlobal() {
  return new Promise<typeof window.dataLayer>((resolve) => {
    if (window.dataLayer) {
      resolve(window.dataLayer);
      return;
    }
    window.addEventListener(DataLayerTrackerEvents.READY, () =>
      resolve(window.dataLayer),
    );
    window.addEventListener(DataLayerTrackerEvents.NOT_FOUND, () => {
      return resolve([]);
    });

    setTimeout(checkForDataLayer, ATTEMPT_INTERVAL);
  });
}
