import { LocalStorageKey, SessionStorageKey } from "@constants/keys";
import type {
  EventArticleMetadata,
  EventMetaData,
  Platform,
} from "@helper/event";
import { getCookieFromPage } from "@browser/plugins/ids/cookie";
import { getDeviceInformation } from "@browser/plugins/ids/device";
import { getUTMParameters } from "@browser/plugins/ids/utm";
import eventId from "@browser/plugins/ids/eventId";
import viewId from "@browser/plugins/ids/viewId";
import { getVisitId } from "@browser/plugins/ids/visitId";
import {
  getDataFromLocalStorage,
  getDataFromWindow,
} from "@browser/plugins/ids/window";
import neuronConfig from "@config/config.json";
import neuronEnvironment from "@core/env";
import {
  CoreEventDataMapping,
  CreateEventOptions,
  TrackedEvent,
} from "@core/event";

const { neuronVersion, neuronCommitHash } = neuronEnvironment;

export type EventMetadataOptions = {
  platformType: Platform;
  onGenerateArticleMetadata?: () => EventArticleMetadata;
  onGetArticleMetadataFromDataLayer?: () => Record<string, unknown>;
};

export const isSameHost = (
  firstHostName: string,
  secondHostName: string,
): boolean => {
  return firstHostName === secondHostName;
};

const onGetArticleMetadataFromDataLayer = (): Record<string, unknown> =>
  (window._data ?? {}) as Record<string, unknown>;

/**
 * This method retrieves the article metadata from window._data (GA3 architecture)
 * @returns {EventMetaData["article"]} - Returns the article metadata object
 */
const onGenerateArticleMetadata = (): EventArticleMetadata => {
  const articleMetadata: EventArticleMetadata = {
    adBlocker: getDataFromWindow("adblocker"),
    abVariant: getDataFromWindow("abVariant"),
    articleId: getDataFromWindow("articleid"),
    articleCount: getDataFromWindow("articlecount"),
    author: getDataFromWindow("author"),
    cueArticleId: getDataFromWindow("cue_articleid"),
    keyword: getDataFromWindow("keyword"),
    page: getDataFromWindow("page"),
    chapter1: getDataFromWindow("chapter1"),
    chapter2: getDataFromWindow("chapter2"),
    chapter3: getDataFromWindow("chapter3"),
    chapter1En: getDataFromWindow("chapter1_en"),
    chapter2En: getDataFromWindow("chapter2_en"),
    chapter3En: getDataFromWindow("chapter3_en"),
    level2: getDataFromWindow("level2"),
    level2Ga: getDataFromWindow("level2_ga"),
    level2Local: getDataFromWindow("level2_local"),
    contentCat: getDataFromWindow("contentcat"),
    contentType: getDataFromWindow("contenttype"),
    pubDate: getDataFromWindow("pubdate"),
    content_publication_utc:
      getDataFromWindow("content_publication_utc") ??
      getDataFromWindow("content_publication_date_utc"),
    content_last_updated_utc:
      getDataFromWindow("content_last_updated_utc") ??
      getDataFromWindow("content_update_date_utc"),
    title: getDataFromWindow("title"),
    titleGa: getDataFromWindow("title_ga"),
    visitorCat: getDataFromWindow("visitorcat"),
  };
  return articleMetadata;
};

export const generateEventMetadata = (
  options?: Partial<EventMetadataOptions>,
): EventMetaData => {
  const generateArticleMetadata =
    options?.onGenerateArticleMetadata ?? onGenerateArticleMetadata;
  const getDataLayer =
    options?.onGetArticleMetadataFromDataLayer ??
    onGetArticleMetadataFromDataLayer;

  const platformType = options?.platformType ?? "script";

  return {
    currentWindowURL: window.location.href,
    neuronVersion: neuronVersion ?? neuronCommitHash ?? "version-unknown",
    platformType: platformType,
    identification: {
      suid: getCookieFromPage({
        dataLocation: "cookie",
        identifier: "suid",
      }),
      mysphw: getCookieFromPage({
        dataLocation: "cookie",
        identifier: "mysphw",
      }),
      visitId: getVisitId(),
      viewId: viewId.get(),
      permutiveId: getDataFromLocalStorage("permutive-id"),
      incomingNeuronId: getLinkDecoratedNeuronId(),
    },
    article: generateArticleMetadata(),
    utm: getUTMParameters(),
    device: getDeviceInformation(),
    dataLayer: getDataLayer(),
  };
};

function getLinkDecoratedNeuronId() {
  return window.sessionStorage.getItem(SessionStorageKey.IncomingNeuronId);
}

export const getUserInitialTimestamp = (): Date | null => {
  const initialTimestamp: string =
    window.sessionStorage.getItem(SessionStorageKey.StartTime) ?? "";
  if (initialTimestamp !== "") {
    return new Date(initialTimestamp);
  }

  return null;
};

export const persistInitialTimestamp = () => {
  // To remove old instances of _neuron.userStartTime in Local Storage.
  window.localStorage.removeItem(LocalStorageKey.StartTime);

  window.sessionStorage.setItem(
    SessionStorageKey.StartTime,
    new Date().toISOString(),
  );
};

export const removeInitialTimestamp = () => {
  window.sessionStorage.removeItem(SessionStorageKey.StartTime);
};

export const constructApiUrl = (url: string, token: string): string => {
  const params = new URLSearchParams();
  params.set("api-key", token);
  const endpoint = `${url}?${params}`;

  return endpoint;
};

export const getMappingFromDivID = (divId: string): string => {
  for (const key in neuronConfig.elementVisibleMapping) {
    if (divId.trim().toLowerCase().startsWith(key) && divId.includes(key)) {
      return neuronConfig.elementVisibleMapping[key];
    }
  }
  return "element";
};

export const getClickMappingFromElementID = (elementId: string): string => {
  for (const key in neuronConfig.adClicksMapping) {
    if (
      elementId.trim().toLowerCase().startsWith(key) &&
      elementId.includes(key)
    ) {
      return neuronConfig.adClicksMapping[key];
    }
  }
  return "element";
};

export const getElementFullDOMPath = (element: HTMLElement | null): string => {
  if (
    !element ||
    !element.parentNode ||
    element.tagName.toLowerCase() === "html"
  ) {
    return "html";
  }

  let selector = element.tagName.toLowerCase();
  // Check if there are siblings nodes
  const siblings =
    element.parentNode && Array.from(element.parentNode.children);
  // We will count the number of sibling tags there is for the given element and get the nth-child position
  const nthChild =
    siblings &&
    siblings
      .filter((sibling) => sibling.tagName === element?.tagName)
      .indexOf(element) + 1;
  // Only append :nth-child(index) if it's greater than :nth-child(1)
  if (nthChild && nthChild > 1) {
    selector += `:nth-child(${nthChild})`;
  }

  return `${getElementFullDOMPath(
    element.parentNode as HTMLElement,
  )} > ${selector}`;
};

export type CustomEventHandler<T = any> = (event: CustomEvent<T>) => void;

export function applyHighwaySchema<
  E extends keyof M,
  M extends CoreEventDataMapping = CoreEventDataMapping,
>(
  event: Pick<TrackedEvent<E, M>, "data" | "eventType">,
  options?: CreateEventOptions,
) {
  return {
    ...event,
    eventDateTime: new Date().toISOString(),
    eventId: eventId.create(),
    meta: generateEventMetadata(options?.metadataOptions),
  };
}

export const isBrowserSafari = () =>
  /^((?!chrome|android).)*safari/i.test(navigator.userAgent);

export function someEventsMatchPatterns<E = Record<string, string>>(
  events: E[],
  patterns: string[],
  getEventName: (event: E) => string,
) {
  return events.some((event) =>
    patterns.some((pattern) =>
      new RegExp(pattern, "i").test(getEventName(event)),
    ),
  );
}
