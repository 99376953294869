{
  "whiteListedDomains": [
    "https://www.straitstimes.com/",
    "https://www.businesstimes.com.sg/",
    "https://www.zaobao.com.sg/",
    "https://www.beritaharian.sg/",
    "https://www.tamilmurasu.com.sg/",
    "https://www.thinkchina.sg/",
    "https://eshop.crsm.dev.ems.sphdigital.com.sg/",
    "https://uattmp.eshop4-cms.sph.com.sg/",
    "https://contest.sph.com.sg/",
    "http://localhost",
    "http://vanilla-rig",
    "https://contest-frontend-uat.sph.com.sg",
    "https://subscribe.sph.com.sg",
    "https://www.sphmagazines.com.sg",
    "https://www.femalemag.com.sg",
    "https://www.iconsingapore.com",
    "https://forums.hardwarezone.com.sg",
    "https://www.hardwarezone.com.sg",
    "https://www.harpersbazaar.com.sg"
  ],
  "domainToSiteMap": {
    "straitstimes.com": "st_sg",
    "businesstimes.com.sg": "bt_sg",
    "zaobao.com.sg": "zb_sg",
    "zaobao.com": "zb_cn",
    "tamilmurasu.com.sg": "tm_sg",
    "beritaharian.sg": "bh_sg",
    "thinkchina.sg": "thinkchina_sg",
    "eshop.crsm.dev.ems.sphdigital.com.sg": "eshop_sg",
    "uattmp.eshop4-cms.sph.com.sg": "eshop_sg",
    "contest.sph.com.sg": "contest_sg",
    "contest-frontend-uat.sph.com.sg": "contest_sg",
    "subscribe.sph.com.sg": "eshop_sg",
    "sphmagazines.com.sg": "utm",
    "femalemag.com.sg": "female",
    "iconsingapore.com": "iconsingapore",
    "forums.hardwarezone.com.sg": "hwzforums",
    "hardwarezone.com.sg": "hwz",
    "harpersbazaar.com.sg": "hpz"
  },
  "cookieExpirationDays": 400,
  "linkDecorationNeuronId": "_nei",
  "neuronId": "neuronId",
  "videoListenerEvents": [
    "play",
    "pause",
    "ended",
    "volumechange",
    "timeupdate",
    "fullscreenchange",
    "seeked",
    "error",
    "loadeddata"
  ],
  "videoEventMapping": {
    "play": "play",
    "pause": "pause",
    "ended": "end",
    "volumechange": "volumeChange",
    "timeupdate": "progress",
    "fullscreenchange": "fullScreenToggle",
    "seeked": "seeked",
    "error": "error",
    "loadeddata": "ready"
  },
  "elementVisibleMapping": {
    "dfp-ad-": "GoogleAds",
    "sph_cdp_": "Campaigns"
  },
  "adClicksMapping": {
    "dfp-ad-": "GoogleAds"
  },
  "dataLayerEvents": {
    "eShop": [
      "eShopCustomEvent",
      "ec.productView",
      "ec.productClick",
      "ec.addToCart",
      "ec.checkout",
      "ec.subscribeSuccess",
      "ec.subscribeFailed",
      "ec.existingSubscriber",
      "ec.dummyPageLoaded"
    ],
    "mySPH": [
      "LoginPV",
      "LoginEvent",
      "SigninFB",
      "mysphCustomEvent",
      "SigninGoogle",
      "mysphCustomPageView",
      "mysphga4CustomPageView",
      "LoginForgotPasswordLink",
      "ForgotPasswordPV",
      "ForgotPasswordSendEmail",
      "ForgotPasswordBackToLogin",
      "ForgotPasswordConfirmPV",
      "ForgotPasswordConfirmResendLink",
      "ForgotPasswordConfirmContinueReading",
      "ForgotPasswordConfirmBacktoLogin",
      "ResetPasswordExpiredPV",
      "ResetPasswordExpiredEvent",
      "ResetPasswordExpiredBacktoLogin",
      "ResetPasswordPV",
      "ResetPasswordEvent",
      "ResetPasswordConfirmPV",
      "ResetPasswordContinuetoLogin",
      "UpdatePasswordPV",
      "UpdatePasswordEvent",
      "UpdatePasswordConfirmPV",
      "UpdatePasswordConfirmContinueReading",
      "ConcurrencyPV",
      "ConcurrencyLogout",
      "ConcurrencyCancel",
      "Logout",
      "SignupNow",
      "SignupNameEmail",
      "SignupNameEmailNext",
      "SingleSignupNameEmail",
      "SignupCaptcha",
      "SignupCaptchaSignup",
      "NormalSignupSuccess",
      "NormalSignupSuccessContinue",
      "SignupGoogle",
      "SignupFacebook",
      "SocialSignupSuccess",
      "SocialSignupSuccessContinue",
      "ExpiredLink",
      "ExpiredLinkResendEmail",
      "EmailVerifiedSuccess",
      "ResendVerificationEmail",
      "ResendVerificationEmailSend",
      "ResendVerificationEmailConfirmed",
      "ResendEmailVerifiedSuccess",
      "SocialExistingAccountVerified",
      "SocialExistingAccountVerifiedContinue",
      "SocialExistingAccountUnVerified",
      "SocialExistingAccountUnVerifiedEvent",
      "SocialExistingAccountUnVerifiedContinue",
      "SocialAccountLinked",
      "SocialAccountLinkedContinue",
      "SocialTokenError"
    ],
    "virtualload": ["virtual_pageview", "virtual_pv", "infinite_scroll"],
    "generic": [".*"]
  },
  "dataLayerBlacklist": ["^gtm\\..*", "sendid"],
  "dataLayerVirtualPageView": [
    "virtual_pageview",
    "virtual_pv",
    "infinite_scroll"
  ]
}
